body {
  margin: 0;
  z-index: 3;
  position: relative;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif, "Sofia Pro Bold", "Sofia Pro Regular", "Poppins Regular";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "SofiaProBold";
  src: local("SofiaProBold"), url(./fonts/SofiaProBold.ttf) format("truetype");
}

@font-face {
  font-family: "SofiaProRegular";
  src: local("SofiaProRegular"),
    url(./fonts/SofiaProRegular.ttf) format("truetype");
}

@font-face {
  font-family: "PoppinsRegular";
  src: local("PoppinsRegular"),
    url(./fonts/PoppinsRegular.ttf) format("truetype");
}
