.animationRight {
  animation-name: rightanimation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.animationLeft {
  animation-name: leftanimation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.animationBottom {
  animation-name: bottomanimation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.animationTop {
  animation-name: topanimation;
  animation-duration: 3s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

.shuffleAnimation {
  animation-name: shuffleAnimation;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.shuffleReverseAnimation {
  animation-name: shuffleReverseAnimation;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.shuffleScaleAnimation {
  animation-name: shuffleScaleAnimation;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.shuffleScaleReverseAnimation {
  animation-name: shuffleScaleReverseAnimation;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.slideAnimation {
  animation-name: slide;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.slideReverseAnimation {
  animation-name: slide-reverse;
  animation-duration: 200ms;
  animation-fill-mode: forwards;
  animation-timing-function: ease-out;
}

.fadeAnimation {
  animation-name: fade;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.scaleInAnimation {
  animation-name: scale-in;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.scaleOutAnimation {
  animation-name: scale-out;
  animation-duration: 400ms;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

@keyframes rightanimation {
  10% {
    right: 0px;
    transform: scale(0.95);
  }
  90% {
    right: var(--how-might-right);
    transform: scale(0.95);
  }
  100% {
    right: var(--how-might-right);
    transform: scale(1);
  }
}

@keyframes bottomanimation {
  10% {
    bottom: 0px;
    transform: scale(0.95);
  }

  90% {
    bottom: var(--so-that-bottom);
    transform: scale(0.95);
  }
  100% {
    bottom: var(--so-that-bottom);
    transform: scale(1);
  }
}

@keyframes leftanimation {
  10% {
    left: 0px;
    transform: scale(0.95);
  }
  90% {
    left: var(--so-that-left);
    transform: scale(0.95);
  }
  100% {
    left: var(--so-that-left);
    transform: scale(1);
  }
}

@keyframes topanimation {
  10% {
    transform: scale(0.95);
    top: 0px;
  }
  90% {
    top: var(--how-might-top);
    transform: scale(0.95);
  }
  100% {
    top: var(--how-might-top);
    transform: scale(1);
  }
}

@keyframes shuffleAnimation {
  50% {
    transform: rotate(90deg);
  }
  100% {
    transform: rotate(180deg);
  }
}

@keyframes shuffleReverseAnimation {
  50% {
    transform: rotate(-90deg);
  }
  100% {
    transform: rotate(-180deg);
  }
}

@keyframes shuffleScaleAnimation {
  100% {
    transform: scale(0.9);
  }
}

@keyframes shuffleScaleReverseAnimation {
  0% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes slide {
  0% {
    left: -65px;
    margin-right: -65px;
  }
  100% {
    left: 0px;
    margin-right: 0px;
  }
}

@keyframes slide-reverse {
  0% {
    left: 0px;
    margin-right: 0px;
  }
  100% {
    left: -65px;
    margin-right: -65px;
  }
}

@keyframes fade-in {
  0% {
    opacity: 0.8;
    z-index: -1;
    background-position: 0px 0px;
  }
  100% {
    opacity: 1;
    z-index: -1;
    background-position: 0px 0px;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
    z-index: -1;
  }
  100% {
    opacity: 0.8;
    z-index: -1;
  }
}

@keyframes scale-in {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.98);
  }
}

@keyframes scale-out {
  0% {
    transform: scale(0.98);
  }
  100% {
    transform: scale(1);
  }
}
